* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	border: none;
	outline: none;
	box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, p, a, button, input, span {
    font-family: $font-type;
}

h1, h2, h3, h4, h5, p, a, button, input, span{
    margin-bottom: 0px !important;
}

a {
	cursor: pointer;
	color: black;
}

a:hover{
	color:$primary
 }

.layout{
	width: 100%;
	min-height: 550px !important;
	display: flex;
}

.mobile {
	width: 100%;
    display: flex;
    flex-direction: column;
}

.pagina {
	padding: 100px 20px;
}

.panel-button{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;;
}

.btn-cancelar{
	cursor: pointer;
	border: 1px solid red;
	color:red;
	padding: 10px;
	margin: 10px;
}

.btn-aceptar{
	cursor: pointer;
	border: 1px solid $primary;
    color: $primary;
    padding: 10px;
    margin: 10px;
}

.btn-app {
	background-color: $primary;
	color:white;
	padding: 10px;
	margin: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}

.alertas {
	color: #856404;
    background-color: #fff3cd;
	border-color: #ffeeba;
	padding: 10px;
	font-size: 0.7rem;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.seguir-comprando{
	margin-top: 20px;
	padding: 6px 50px;
	border-radius: 5px;
	background-color: $primary;
	height: 40px;
	color:white;
	cursor: pointer;
	float: right;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	padding: 10px 0px;
	h2 {
		display: initial;
		border-bottom: 2px solid $primary;
		font-weight: 900;
	}
}

.btn-back {
	display: flex;
	width: 100%;
	align-items: center;
	position: absolute;
	left: 20px;
    top: 20px;
	img {
		margin-right: 20px;
	}
	p{
		font-size: 0.8rem;
		color: $primary;
		font-weight: bold;
	}
}

// .carousel * {
//     border-radius: 25px !important;
// }



@media only screen and (min-width: $device-sm) {

}

@media only screen and (min-width: $device-md) {
	.mobile {
		flex-direction: row;
	}	
}

@media only screen and (min-width: $device-lg) {

}

@media only screen and (min-width: $device-xl) {

}